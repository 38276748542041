<template>
  <div class="container">
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Retailer</label>
      <div class="col-2">
        <select v-model="form.type" class="form-select col-2">
          <option v-for="(item, index) in retailerList" :key="index" :value="item">{{item}}</option>
        </select>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="col-1 col-form-label">Gift Card</label>
      <div class="col-10">
        <textarea
            v-model="form.data"
            class="form-control col-8"
            rows="15"
            placeholder="Format: 200+738192834892+1203    200CardNumber:738192834892SecurityCode(PIN):1203     200E-GiftCard:738192834892SecurityCode(PIN):1203"
        ></textarea>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-4 col-form-label">
        <button type="button" class="btn btn-primary" @click="formSubmit">Save Changes</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { encode } from "../util.js"

export default {
  name: "GiftCardBatch",
  data: function () {
    return {
      breadcrumbs: [{ name: "Gift Card", link: "/giftcard" }, { name: "Load Gift Card" }],
      submitted: false,
      alertContent: "",
      alertClass: "alert-danger",
      retailerList: ["Best Buy", "Amazon", "Walmart", "Costco", "Dell", "Microsoft", "Google", "Sony", "Target", "Gamestop", "HP", "Sams Club", "HSN", "Sephora", "eBay", "Staples"],
      form: { type: "Best Buy" }
    };
  },
  methods: {
    formSubmit() {
      if (this.submitted) {
        return;
      }
      if (!this.form.data) {
        return;
      }
      this.submitted = true;
      
      var processedGiftCardList = [];
      this.form.data = this.form.data.replaceAll(" ", "").replaceAll("CardNumber:", "+").replaceAll("E-GiftCard:", "+").replaceAll("SecurityCode(PIN):", "+");
      var giftCardList = this.form.data.split(/\n/);
      for (let i=0; i<giftCardList.length; i++) {
        var giftCard = giftCardList[i].split("+");
        if (giftCard.length !== 3) {
          this.alertContent = "Please fix data format!";
          this.alertClass = "alert-danger";
          this.submitted = false;
          return;
        }
        processedGiftCardList.push({ value: giftCard[0], card: giftCard[1], pin: encode(giftCard[2]) })
      }

      axios.post("/giftcard/batch", { type: this.form.type, data: processedGiftCardList })
        .then((response) => {
          this.submitted = false;
          this.alertContent = response.data;
          this.alertClass = "alert-success";
        })
        .catch((error) => {
          this.alertContent = error.response.data;
          this.alertClass = "alert-danger";
          this.submitted = false;
        });
    },
  },
};
</script>
